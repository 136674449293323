<template>
    <div class="Loading" @click="closeLoading">
       
    </div>
  </template>
  
  <script>
 
  export default {
    name: "Loading",
    data() {
      return {
        
      };
    },
  
    props: {
       
    },
    created() {
    
    },
    methods: {
        closeLoading() {
            this.$emit("closeLoading");
            },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .Loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10001;
    // animation: show 0.36s;
    // -webkit-animation: show 0.36s; /* Safari */
  }
   
  </style>