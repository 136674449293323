<template>
  <div class="othersop">
    <slot>
      <div class="tabbaroption">
        <div class="mask"><!-- glass --></div>
        <div class="topbox"></div>
        <!-- <hr /> -->
        <!-- 分割线 -->
        <div class="selector1">
          <!-- 滑动选择行1 -->
          <div class="slide1">
            <div class="direction" @click="clickArrow('inside', 'left')">
              <img
                src="../assets/options/pre.png"
                ref="prepng"
                lt=""
                id="prepng"
              />
            </div>
            <!-- 箭头盒子 -->
            <div class="selected1">
              <ul class="litseletor1" ref="litseletor1">
                <li
                  class="color-selected1 insideColorLi"
                  v-for="(classify, index) in classList"
                  :key="'class_' + index"
                  :id="'class_' + index"
                  @click="icsClick('class_' + classify.id)"
                  :style="{
                    background:
                      classselector_index == 'class_' + classify.id
                        ? 'rgb(0 0 0 / 10%)'
                        : '',
                  }"
                >
                  <img
                    draggable="false"
                    class="ics-img"
                    :src="classify.src"
                    alt=""
                  />
                </li>
              </ul>
            </div>
            <!-- inside -->
            <div class="direction" @click="clickArrow('inside', 'right')">
              <img
                src="../assets/options/next.png"
                ref="nextpng"
                alt=""
                id="nextpng"
              />
            </div>
            <!-- 箭头盒子 -->
          </div>
          <!-- 滑动选择行2 -->
          <div class="slide">
            <div class="direction" @click="clickArrow('outside', 'left')">
              <img
                src="../assets/options/pre.png"
                ref="prepng2"
                alt=""
                id="prepng2"
              />
            </div>
            <!-- 箭头盒子 -->
            <div class="selected">
              <ul class="litseletor" ref="litseletor2">
                <li
                  v-for="(innovation, index) in innovationList"
                  :key="index"
                  :id="innovation.id"
                  @click="ocsClick(index)"
                  :style="{
                    background:
                      innovationselector_index == innovation.id
                        ? 'rgb(0 0 0 / 10%)'
                        : '',
                  }"
                  class="outsideColorLi"
                >
                  <div
                    v-if="!innovation.img"
                    class="color-selected"
                    :style="{
                      background: innovation.dfColor,
                    }"
                  >
                    <img
                      draggable="false"
                      class="ocs-img"
                      :src="require(`../assets/options/button_mask.png`)"
                      alt=""
                    />
                  </div>
                  <div v-else class="color-selected">
                    <img
                      draggable="false"
                      :src="innovation.img"
                      alt=""
                      :style="{
                        height:
                          classselector_index == 'class_1' ? '75px' : '60px',
                      }"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <!-- outside -->
            <div class="direction" @click="clickArrow('outside', 'right')">
              <img
                src="../assets/options/next.png"
                ref="nextpng2"
                alt=""
                id="nextpng2"
              />
            </div>
            <!-- 箭头盒子 -->
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
// 滚动插件
import smoothscroll from "smoothscroll-polyfill";
import data from "../assets/data/innovation.json";
import { SetColor, LoadProduct } from "../Ledmodule/model.js";
export default {
  props: ["currentProduct", "needLoad", "currentInside", "currentOutside"],
  name: "Othersop",
  data() {
    return {
      classselector_index: "class_5",
      innovationselector_index: "",
      classList: [
        { id: 5, src: require("../assets/morelogo/6.png") },
        { id: 4, src: require("../assets/morelogo/5.png") },
        { id: 2, src: require("../assets/morelogo/3.png") },
        { id: 1, src: require("../assets/morelogo/2.png") },
      ],
      innovationList: [],
      data: [],
      isMove: false,
      mousedown: false,
      mousedown1: false,
      startTarget: "",
    };
  },
  mounted: function () {
    // 解决移动端scrollTo的behavior："smooth"无效的问题
    smoothscroll.polyfill();
    this.objToArr(data);
    // 鼠标在创新上移动
    let _this = this;
    this.$refs.litseletor1.addEventListener("mousedown", this.mousedown1Fn);
    this.$refs.litseletor2.addEventListener("mousedown", this.mousedown2Fn);
    this.$refs.litseletor1.addEventListener("scroll", this.judgeArrow1Fn);
    this.$refs.litseletor2.addEventListener("scroll", this.judgeArrow2Fn);
    this.ocsClick(0, this.needLoad);
  },
  methods: {
    mousedown1Fn: function (e) {
      let _this = this;
      _this.mousedown = true;
      document.body.style.cursor = "grab";
      _this.startTarget = _this.$refs.litseletor1.scrollLeft + e.pageX;
      document.addEventListener("mousemove", _this.mousemove1Fn);
      document.addEventListener("mouseup", _this.mouseup1Fn);
    },
    mousemove1Fn: function (e) {
      let _this = this;
      if (_this.mousedown) {
        let endTarget;
        endTarget = e.pageX;
        _this.$refs.litseletor1.scrollLeft = _this.startTarget - endTarget;
        _this.isMove = true;
      }
      _this.judgeArrow("top", _this.$refs.litseletor1.scrollLeft);
    },
    mouseup1Fn: function (e) {
      let _this = this;
      _this.mousedown = false;
      document.body.style.cursor = "default";
      _this.isMove = false;
    },
    mousedown2Fn: function (e) {
      let _this = this;
      _this.mousedown1 = true;
      let startTarget;
      document.body.style.cursor = "grab";
      _this.startTarget = _this.$refs.litseletor2.scrollLeft + e.pageX;
      document.addEventListener("mousemove", _this.mousemove2Fn);
      document.addEventListener("mouseup", _this.mouseup2Fn);
    },
    mousemove2Fn: function (e) {
      let _this = this;
      if (_this.mousedown1) {
        let endTarget;
        endTarget = e.pageX;
        _this.$refs.litseletor2.scrollLeft = _this.startTarget - endTarget;
        _this.isMove = true;
      }
      _this.judgeArrow("down", _this.$refs.litseletor2.scrollLeft);
    },
    mouseup2Fn: function () {
      let _this = this;
      _this.mousedown1 = false;
      document.body.style.cursor = "default";
      setTimeout(() => {
        _this.isMove = false;
      }, 300);
    },
    judgeArrow1Fn: function (e) {
      this.judgeArrow("top", this.$refs.litseletor1.scrollLeft);
    },
    judgeArrow2Fn: function (e) {
      this.judgeArrow("down", this.$refs.litseletor2.scrollLeft);
    },
    // 点击箭头 类型，方向
    clickArrow(type, direction) {
      if (type === "inside") {
        let scrolled = this.$refs.litseletor1.scrollLeft;
        if (direction === "left") {
          // 当前滚动的值
          scrolled = scrolled - 70;
          this.$refs.litseletor1.scrollTo({
            top: 0,
            left: scrolled,
            behavior: "smooth",
          });
          // this.$refs.litseletor.scrollLeft = scrolled;
        } else if (direction === "right") {
          // 当前滚动的值
          scrolled = scrolled + 70;
          this.$refs.litseletor1.scrollTo({
            top: 0,
            left: scrolled,
            behavior: "smooth",
          });
        }
        this.judgeArrow("top", scrolled);
      } else {
        let scrolled = this.$refs.litseletor2.scrollLeft;
        if (direction === "left") {
          // 当前滚动的值
          scrolled = scrolled - 70;
          this.$refs.litseletor2.scrollTo({
            top: 0,
            left: scrolled,
            behavior: "smooth",
          });
          // this.$refs.litseletor.scrollLeft = scrolled;
        } else if (direction === "right") {
          // 当前滚动的值
          scrolled = scrolled + 70;
          this.$refs.litseletor2.scrollTo({
            top: 0,
            left: scrolled,
            behavior: "smooth",
          });
        }
        this.judgeArrow("down", scrolled);
      }
    },
    // 判断箭头
    judgeArrow(type, scrolled) {
      if (type === "top") {
        // 判断左箭头
        if (scrolled <= 20) {
          this.$refs.prepng.style.opacity = 0.2;
        } else {
          this.$refs.prepng.style.opacity = 1;
        }
        // 判断右箭头
        if (
          scrolled + document.querySelector(".selected1").clientWidth >
          (document.querySelector(".insideColorLi").clientWidth + 9) *
            (this.classList.length - 0.5)
        ) {
          this.$refs.nextpng.style.opacity = 0.2;
        } else {
          this.$refs.nextpng.style.opacity = 1;
        }
      } else if (type === "down") {
        // 判断左箭头
        if (scrolled <= 20) {
          this.$refs.prepng2.style.opacity = 0.2;
        } else {
          this.$refs.prepng2.style.opacity = 1;
        }
        // 判断右箭头
        if (
          scrolled + document.querySelector(".selected1").clientWidth >
          document.querySelector(".outsideColorLi").clientWidth *
            (this.innovationList.length - 0.5)
        ) {
          this.$refs.nextpng2.style.opacity = 0.2;
        } else {
          this.$refs.nextpng2.style.opacity = 1;
        }
      }
    },
    icsClick(index) {
      if (this.isMove) return;
      this.classselector_index = index;
      this.$emit("showotherInfo", index);
      this.checkArray();
      this.ocsClick(0);
      //
      this.$refs.litseletor2.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.judgeArrow("down", 0);
    },
    deepClone1(obj) {
      var _obj = JSON.stringify(obj),
        objClone = JSON.parse(_obj);
      return objClone;
    },
    ocsClick(index, hasLoad) {
      if (this.isMove) return;
      let _this = this;
      let insideInnovation = "";
      let outsideInnovation = "";
      let insideData = this.deepClone1(this.innovationList[index].inside);
      let outsideData = this.deepClone1(this.innovationList[index].outside);
      if (insideData.Texture != undefined && insideData.Texture.length > 0)
        insideInnovation = "innovation";
      if (outsideData.Texture != undefined && outsideData.Texture.length > 0)
        outsideInnovation = "innovation";
      insideData.Material = insideData.Material + "_Inside";
      outsideData.Material = outsideData.Material + "_Outside";
      if (hasLoad) {
        LoadProduct(
          _this.currentProduct,
          this.currentInside,
          this.currentOutside,
          "",
          "",
          function () {
            SetColor(
              insideData,
              "inside",
              "click",
              insideInnovation,
              _this.currentProduct
            );
            SetColor(
              outsideData,
              "outside",
              "click",
              outsideInnovation,
              _this.currentProduct
            );
          }
        );
      } else {
        SetColor(
          insideData,
          "inside",
          "click",
          insideInnovation,
          this.getUrlKey("product")
        );
        SetColor(
          outsideData,
          "outside",
          "click",
          outsideInnovation,
          this.getUrlKey("product")
        );
      }
      this.innovationselector_index = this.innovationList[index].id;
    },
    getUrlKey(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    checkArray() {
      let arr = [];
      this.innovationList = [];
      var _this = this;
      this.data.forEach(function (item) {
        if (item["Material"] == _this.classselector_index.split("_")[1]) {
          arr.push(item);
        }
      });
      this.innovationList = arr;
    },
    async objToArr(obj) {
      let insideArr0 = [];
      for (var i in obj) {
        var k = i.split("_")[1];
        var arr = [];
        var Texture = obj[i]["outside"]["Texture"];

        arr = obj[i];
        arr["id"] = i;
        arr["dfColor"] = obj[i]["outside"]["Color"][0];

        this.data.push(arr);
        if (
          obj[i]["Material"] == 1 ||
          obj[i]["Material"] == 5 ||
          obj[i]["Material"] == 2 ||
          obj[i]["Material"] == 4
        ) {
          arr["img"] = Texture[0].split(".")[0] + "-mini.png";
        } else if (Texture) {
          this.updateImg(Texture[0].split(".")[0] + "-mini.jpg", k);
        }
        if (obj[i].Material == this.classselector_index.split("_")[1]) {
          arr = obj[i];
          arr["id"] = i;
          if (Texture === undefined) {
            arr["dfColor"] = obj[i]["outside"]["Color"][0];
          } else {
            // arr["img"] = await this.createImage(
            //   Texture[0].split(".")[0] + "-mini.jpg"
            // );
            // arr["img"] = Texture[0];
            // console.log(Texture[0]);
          }

          insideArr0[insideArr0.length] = arr;
        }
      }

      this.innovationList = insideArr0;
    },
    updateImg(url, key) {
      let _this = this;
      let imgObj = new Image();
      imgObj.src = "/" + url;
      var tnCanvas = document.createElement("canvas");
      var tnCanvasContext = tnCanvas.getContext("2d");
      tnCanvas.width = 30;
      tnCanvas.height = 30;
      imgObj.onload = function () {
        tnCanvasContext.drawImage(imgObj, 0, 0, 120, 120, 0, 0, 30, 30);
        var imgData = tnCanvas.toDataURL();
        _this.data[key]["img"] = imgData;
      };
    },

    async createImage(url) {
      var imgData = null;
      var promise = new Promise(function (resolve) {
        let imgObj = new Image();
        imgObj.src = "/" + url;
        var tnCanvas = document.createElement("canvas");
        var tnCanvasContext = tnCanvas.getContext("2d");
        tnCanvas.width = 30;
        tnCanvas.height = 30;
        imgObj.onload = function () {
          tnCanvasContext.drawImage(imgObj, 0, 0, 120, 120, 0, 0, 30, 30);
          imgData = tnCanvas.toDataURL();
          resolve(imgData);
        };
      });
      return promise;
    },
  },
  beforeDestroy() {
    this.$refs.litseletor1.removeEventListener("mousedown", this.mousedown1Fn);
    document.removeEventListener("mousemove", this.mousemove1Fn);
    document.removeEventListener("mouseup", this.mouseup1Fn);
    this.$refs.litseletor2.removeEventListener("mousedown", this.mousedown2Fn);
    document.removeEventListener("mousemove", this.mousemove2Fn);
    document.removeEventListener("mouseup", this.mouseup2Fn);
    this.$refs.litseletor1.removeEventListener("scroll", this.judgeArrow1Fn);
    this.$refs.litseletor2.removeEventListener("scroll", this.judgeArrow2Fn);
  },
};
</script>

<style scoped>
.tabbaroption {
  position: absolute;
  bottom: 30px;
  padding-bottom: 20px;
  height: auto;
  width: 100%;
  margin: 0;
  /*  display: none */
  /*  animation: none; */
  pointer-events: all;
  box-sizing: border-box;
  border: 2px solid #ffffff80;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-image: linear-gradient(#0000000f, #ffffff14);
  overflow: hidden;
  /* filter: blur(5px); */
  /* background: white; */
  animation: upshow 0.6s 0s ease 1;
  animation-fill-mode: forwards;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(#0000000f, #ffffff14); */
  background: url(../assets/options/mask.png);
  /* filter: blur(1px); */
  opacity: 0.75;
}
.tabbaroption hr {
  margin: 0px;
  width: calc(100% - 42px);
  height: 1px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-sizing: border-box;
  border: none;
}
.topbox {
  position: relative;
  top: 0;
  height: auto;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 auto;
}

.search {
  position: relative;
  top: 0px;
  margin: 6px 0px;
  opacity: 1;
  z-index: 1;
}
.inputed {
  /* background-color: #fff; */
  border-radius: 20px;

  border: 2.5px;
  background-image: url("../assets/options/search.png");
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: auto 50%;
  height: 20px;
  width: 200px;
  outline: none;
  text-indent: 6px;
  background-color: #f5f6f8;
  font-size: 12px;
  color: grey;
  user-select: text;
}
.selector {
  position: relative;
  border: none;
  top: 0px;
  width: 100%;
  height: auto;
  margin: 10px 0px;
  user-select: none;
}

.direction {
  background: transparent;
  width: auto;
  /* height: 32px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.direction > img {
  height: 15px;
  width: auto;
}
.selected {
  padding: 0px;
  width: 88%;
  margin-top: 2px;
}
.litseletor {
  background-color: transparent;
  list-style-type: none;
  display: flex;
  padding: 0px;
  margin: 0px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 5.5px 5px;
  overflow-y: hidden;
  overflow-x: scroll;
  height: 100%;
}
.litseletor li {
  /* margin: 0 12px; */
  height: 100%;
  display: flex;
  align-items: center;
}
.litseletor::-webkit-scrollbar {
  display: none;
}
.litseletor li {
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
}
.slide {
  height: 58px;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.color-selected {
  margin-right: 12px;
  margin: 4px;
  /* width: 22px; */
  height: 35px;
  /* border:1px solid blue; */
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  /* 判断 */
  /* background:rgb(0 0 0 / 10%); */
  display: flex;
  align-items: center;
}
.color-selected img {
  height: 60px;
  width: auto;
  /* border:1px solid red; */
  pointer-events: none;
  /* border-radius: 50%; */
}
.slide1 {
  height: 58px;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 8px 0px;
}
.color-selected1 {
  margin-right: 9px;
  /* padding: 4px; */
  width: auto;
  height: 46px;
  /* border:1px solid blue; */
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  /* 判断 */
  /* background:rgb(0 0 0 / 10%); */
}
.color-selected1 img {
  height: 46px;
  width: auto;
  /* border:1px solid red; */
  pointer-events: none;
  margin: 1px;
  padding: 0px 6px;
}
.selected1 {
  padding: 0px;
  width: 88%;
}
.litseletor1 {
  background-color: transparent;
  list-style-type: none;
  display: flex;
  padding: 0px;
  margin: 0px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 5.5px 5px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.litseletor1::-webkit-scrollbar {
  display: none;
}
.litseletor1,
.litseletor {
  position: relative;
  left: 0;
  animation: toLeft 1s;
}
@keyframes toLeft {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
#prepng {
  opacity: 0.2;
}
#nextpng {
  opacity: 1;
}
#prepng2 {
  opacity: 0.2;
}
#nextpng2 {
  opacity: 1;
}
</style>

<style lang="less" scoped>
.direction {
  background: transparent;
  width: auto;
  height: 100%;
  align-items: center;
  display: flex;
  /* height: 32px; */
  // position: relative;
  /* top: 50%; */
  /* transform: translateY(-50%);*/
  cursor: pointer;
}
.direction > img {
  height: 15px;
  width: auto;
}

.selector1 {
  position: relative;
  border: none;
  top: 0px;
  width: 100%;
  height: auto;
  margin: 5px 0px 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .slide,
  .slide1 {
    width: 100%;
    height: 58px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    .selected1,
    .selected {
      width: 86%;
      height: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
    }
  }
  .slide {
    padding: 0 20px 5px;
  }
}
</style>