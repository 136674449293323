<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  created() {
    // var ua = navigator.userAgent.toLowerCase();
    // var isWXWork = ua.match(/wxwork/i) == "wxwork";
    // var isWeixin = !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
    // // var isMobile = false;
    // // var isDesktop = false;
    // // if (
    // //   navigator.userAgent.match(
    // //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i
    // //   )
    // // ) {
    // //   isMobile = true;
    // // } else {
    // //   isDesktop = true;
    // // }
    // // 如果是在微信
    // if (isWeixin) {
    //   window.location.href="weixin://dl/business/?t=87JujeW4NYk"
    // }
    // 如果是在pc
    // 如果是在移动设备
  },
};
</script>
<style >
html,
body,
.app {
  width: 100%;
  height: 100%;
}
body {
  font-family: sans-serif;
}
* {
  user-select: none;
}
p,
h4 {
  padding: 0;

  margin: 0;
}
ul,
dl,
dt,
dd {
  list-style: none;
  padding: 0;
  margin: 0;
}
img[src=""],
img:not([src]) {
  opacity: 0;
}
</style>
