<template>
  <div class="Version">
    <div class="Version-container">
      <!-- 提示图片 -->
      <div class="tipImg">
        <img src="../assets/tip.png" alt="" />
      </div>
      <!-- 文本内容 -->
      <dl class="article">
        <dt style="color: rgb(0, 91, 172);">
          {{ articleC.title }} <span>{{ articleC.tip }} </span>
        </dt>
     
       
        <dd style="color: rgb(0, 91, 172);" v-for="(item, index) in articleC.p" :key="index">
          <h4  >{{ articleC.h4text[index] }}</h4>
          <span v-html="item"></span>
        </dd>
      </dl>
      <dl class="article">
        <dt>
          {{ articleV.title }} <span>{{ articleV.tip }}</span>
        </dt>
        <dd v-for="(item, index) in articleV.p" :key="index">
          {{ item }}
        </dd>
      </dl>
      <!-- 关闭按钮 -->
      <div class="close" @click="close">
        <span v-if="OnceCountDown">Close after ({{ Countdown }}) seconds </span>
        <span
          class="closeBtn"
          :style="{ letterSpacing: languageType === 'en' ? '' : '0.2em' }"
          >{{ languageType === "en" ? "Close" : "关闭" }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["ShowVersion", "Countdown", "OnceCountDown", "languageType"],
  data() {
    return {
      // 颜色标准文本

      articleColor: {
        en: {
          title: "Color standard",
          tip: " ",
          h4text:[
            'Calibration conditions:',
            '','Use suggestions:'
          ],
          p: [
          " D65 Artificial Daylight; Iphone 12 display with “Ture Tone” function; Brightness 85-90%",
            "Illumination: 3000-3600 Lux",
            " Operate in areas with diffuse reflection of sunlight (avoid direct sunlight), sensitive colors: grey, yellowish green and other light colors."
          ],
        },
        cn: {
          title: "调色标准",
          tip: "",
          h4text:['校准条件:','','使用建议：'],
          p: [
          "D65标准光源箱，iphone12默认开启原彩显示，亮度85-90%",
          "照度：3000-3600 LUX",
          " 打开窗户在日光漫反射区域做颜色对比；尤其灰色，黄绿以及浅色系等显色受光线影响较大。",
          ],
        },
      },
      articleC: "",
      articleVersion: {
        en: {
          title: "Version update content ",
          tip: "Version 4.2.0",
          p: [
          "· Upgrade 2872 more  colors;",
          "· Upgrade popular color posters",
          ],
        },
        cn: {
          title: "版本更新内容",
          tip: "Version 4.2.0",
          p: [
          "· 新增颜色数据2872个;",
          "· 新增流行色海报;",
          ],
        },
      },
      articleV: "",
    };
  },
  created() {
    this.articleC = this.articleColor.en;
    this.articleV = this.articleVersion.en;
  },
  methods: {
    close() {
      this.$emit("closeVersion");
    },
  },
  watch: {
    languageType(newVal, oldVal) {
      if (newVal === "en") {
        this.articleC = this.articleColor.en;
        this.articleV = this.articleVersion.en;
      } else {
        this.articleC = this.articleColor.cn;
        this.articleV = this.articleVersion.cn;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Version {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  .Version-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 450px;
    background-color: #f4f4f4;
    padding: 15px 0 15px 55px;
    box-sizing: border-box;
    box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.3);
    .tipImg {
      position: absolute;
      height: 22px;
      transform: translateX(-160%);
      img {
        height: 100%;
        width: auto;
      }
    }

    .article {
      width: 100%;
      border-bottom: 2px solid #bfbfbf;
      padding-bottom: 10px;
      background-color: #f4f4f4;
      margin-bottom: 8px;
      dt {
        color: #005bac;
        font-weight: bold;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          font-size: 12px;
          padding-right: 20px;
          color: #595757;
          font-weight: normal;
        }
      }
      dd {
        font-size: 12px;
        line-height: 18px;
        color: #595757;
        padding-right: 20px;
        margin-bottom: 2px;
        h4{
          display: inline;
        }
      }
    }

    .close {
      color: #595757;
      font-size: 12px;
      padding-right: 20px;
      float: right;
      margin-top: 5px;
      .closeBtn {
        display: inline-block;
        padding: 5px 10px;
        background-color: #bfbfbf;
        color: #fff;
        border-radius: 5px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>