var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formbg"},[_c('div',{staticClass:"memberForm"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.memberFormtype=='login'),expression:"memberFormtype=='login'"}]},[_c('div',{staticClass:"login_from"},[_c('div',{staticClass:"form_header"},[_c('div',{staticClass:"form-title"},[_vm._v("登录")]),_c('div',{staticClass:"form-close"},[_c('img',{attrs:{"src":require("../assets/options/close.png"),"alt":""},on:{"click":_vm.doClose}})])]),_c('div',{staticClass:"form_content"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"form-item"},[_c('div',{staticClass:"toReg",on:{"click":_vm.toReg}},[_vm._v(" 没有账号？立即注册 ")])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.memberFormtype=='reg'),expression:"memberFormtype=='reg'"}]},[_c('div',{staticClass:"login_from"},[_c('div',{staticClass:"form_header"},[_c('div',{staticClass:"form-title"},[_vm._v("注册")]),_c('div',{staticClass:"form-close"},[_c('img',{attrs:{"src":require("../assets/options/close.png"),"alt":""},on:{"click":_vm.doClose}})])]),_c('div',{staticClass:"form_content"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"form-item",staticStyle:{"text-align":"center"}},[_c('input',{attrs:{"type":"checkbox"}}),_vm._v(" "),_c('span',{staticClass:"register_protocol",on:{"click":_vm.showPageView}},[_vm._v(" 注册协议")])]),_vm._m(8)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item",staticStyle:{"position":"relative"}},[_c('i',{staticClass:"icons icon-user"}),_c('input',{staticClass:"form-input",attrs:{"placeholder":"请输入账号","type":"text","name":"","id":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item",staticStyle:{"position":"relative"}},[_c('i',{staticClass:"icons icon-pwd"}),_c('input',{staticClass:"form-input",attrs:{"placeholder":"请输入密码","type":"password","name":"","id":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item"},[_c('button',{staticClass:"userLogin"},[_vm._v("登录")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item",staticStyle:{"position":"relative"}},[_c('i',{staticClass:"icons icon-user"}),_c('input',{staticClass:"form-input",attrs:{"placeholder":"请输入账号","type":"text","name":"","id":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item",staticStyle:{"position":"relative"}},[_c('i',{staticClass:"icons icon-pwd"}),_c('input',{staticClass:"form-input",attrs:{"placeholder":"请输入密码","type":"password","name":"","id":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item",staticStyle:{"position":"relative"}},[_c('i',{staticClass:"icons icon-pwd"}),_c('input',{staticClass:"form-input",attrs:{"placeholder":"请再次输入密码","type":"password","name":"","id":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item",staticStyle:{"position":"relative"}},[_c('i',{staticClass:"icons icon-phone"}),_c('input',{staticClass:"form-input",attrs:{"placeholder":"请输入手机号","type":"password","name":"","id":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item",staticStyle:{"position":"relative"}},[_c('i',{staticClass:"icons icon-msg"}),_c('input',{staticClass:"form-input",attrs:{"placeholder":"请输入验证码","type":"password","name":"","id":""}}),_c('span',{staticClass:"sendCode"},[_vm._v("请输入验证码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item"},[_c('button',{staticClass:"userLogin"},[_vm._v("注册")])])
}]

export { render, staticRenderFns }