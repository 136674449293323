<template>
  <div class="pageview">
      <div class="page-header">
          <div class="bg">
                <div class="logo">
                    <img src="../assets/options/white_logo.png" alt="">
                </div>
                <div class="goback" @click="toReg">
                    <img src="../assets/options/fanhui.png" alt="">
                </div>
                <div class="logopic">
                    <img src="../assets/options/fwxy_logo.png" alt="">
                </div>
            </div>
      </div>
      <div class="page-content">
         <div class="content-title">
             浙江鹏孚隆新材料有限公司
         </div>
         <div class="content-id">
             账号：123456789
         </div>
         <div class="content-check">
             <input type="checkbox">服务协议
         </div>
         <div class="content-info">
             <p>欢迎使用浙江鹏孚隆新材料有限公司（以下简称鹏孚隆）的软甲服务</p>
             <p>欢迎使用浙江鹏孚隆新材料有限公司（以下简称鹏孚隆）的软甲服务</p>
             <p>欢迎使用浙江鹏孚隆新材料有限公司（以下简称鹏孚隆）的软甲服务</p>
             <p>欢迎使用浙江鹏孚隆新材料有限公司（以下简称鹏孚隆）的软甲服务</p>
             <p>欢迎使用浙江鹏孚隆新材料有限公司（以下简称鹏孚隆）的软甲服务</p>
             <p>欢迎使用浙江鹏孚隆新材料有限公司（以下简称鹏孚隆）的软甲服务</p>
             <p>欢迎使用浙江鹏孚隆新材料有限公司（以下简称鹏孚隆）的软甲服务</p>
         </div>
      </div>
  </div>
</template>

<script>
//import func from 'vue-editor-bridge';
export default {
  name: "Member",
  data() {
    return {
        
    };
  },
 
  methods:{
      toReg(){
          this.$emit('doMemberForm' ,'reg');
      }
     
  }
 
};
</script>

<style scoped>
 .pageview{
     width: 100%;
     height: 100%;
     background-color: #F5F5F5;
     position:absolute;
      z-index: 10004;
       overflow: scroll;
     
 }
 .page-header{
     background-color: #345D9F;
    
     width: 100%;
     height: 180px;
     border-bottom-left-radius: 50px;
     border-bottom-right-radius: 50px;
 }
 .page-header .bg{
    background: url(../assets/options/wenlu.png) no-repeat;
    background-position-x: right;
    background-size: 150% 120%;   
    width: 100%;
    height: 100%;
 }
 .logo{
     padding: 20px;
     height:50px
 }
 .goback img{
     width: 20px;
     height: 20px;
     padding-left: 20px;
 }
 .logopic{
    margin-left: calc(50% - 50px);
    margin-top: 10px;
     background-color: #fff;
     border-radius: 50%;
     width: 100px;
     height: 100px;
 }
 .logopic img{
     width: 100px;
     height: 100px;
 }
 .page-content{
     margin-top: 60px;
 }
 .content-title{
     text-align: center;
     font-size: 20px;
     font-weight: 900;
 }
 .content-id{
     text-align: center;
     color: #ccc;
     margin-top:10px; 
     font-size:12px
 }
 .content-check{
     padding-left: 20px;
     font-size: 16px;
     font-weight: 600;
 }
 .content-info{
     padding:10px;
     background-color: #fff;
     margin:10px 20px;
    
 }
 
 
</style>