<template>
  <div class="preloder">
    <slot>
      <div id="simple-preloader-background">
        <div id="simple-preloader-container">
          <img class="simple-preloader-img" src="../assets/logo/loading.png"
          alt= />
          <div class="simple-preloader-box">
            <div id="simple-preloader-width"></div>
          </div>
        </div></div
    ></slot>
  </div>
</template>

<script>
export default {
  name: "Preloader",
  data() {
    return {};
  },
};
</script>

<style>
@import "../Ledmodule/model.css";
@keyframes hideLoading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes showLoading {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

</style>
