<template>
  <div class="Share" @click="closeShare">
    <div style="share-info" @touchstart="start" @touchend="end">
      <div class="share-form" ref="shareForm">
        <img class="compositeImage" :src="compositeSrc" alt="" />
        <div class="form_header">
          <div class="form-title">
            <img src="../assets/logo/shareTop.png" alt="" />
          </div>
          <!-- <div class="form-close">
            <p class="cn">我们无意于创造一种色彩</p>
            <p class="cn">每个作品专注提取自然颜色</p>
            <p class="en">Not only create a color</p>
            <p class="en">Focus on presenting natural colors</p>
          </div> -->
        </div>
        <div class="form_content">
          <!-- 截图 -->
          <div class="screenBox form-item" ref="compositeDom">
            <img ref="mainImage" class="mainImage" :src="imgsrc" alt="" />
            <!--img ref="textImage" class="textImage" :src="textsrc" alt="" /-->
            <div class="textImage colorContent">
                <div
                class="title"
                id="inside_text"
                v-show="dbclickStatus!='2'"
              >
                <h4>
                  {{ top_text1[0] }}
                  <span class="color_text">-{{ top_text1[1] }}</span>
                </h4>
                <p class="color_text">{{ top_text1[2] }} {{ top_text1[3] }}</p>
              </div>
              <div
                class="title"
                id="outside_text"
                v-show="dbclickStatus!='1'"
              >
                <h4>
                  {{ top_text2[0] }}
                  <span class="color_text">-{{ top_text2[1] }}</span>
                </h4>
                <p class="color_text">
                  {{ top_text2[2]
                  }} 
                  {{ top_text2[3] }}
                </p>
              </div>
            
            </div>
          </div>
          <!-- 二维码 -->
          <div class="form-item">
            <div class="item-left">
              <img :src="qrcode" alt="" />
            </div>
            <div class="item-right">
              <img src="../assets/logo/shareBottom.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import wx from "weixin-js-sdk";
import QRCode from "qrcode";
const Base64 = require('js-base64').Base64
import { urlencoded } from "body-parser";
export default {
  name: "Shares",
  data() {
    return {
      imgsrc: null,
      textsrc: null,
      compositeSrc: null,
      qrcode: "",
     
    };
  },

  props: {
    memberFormtype: {
      type: String,
    },
    top_text1:{
      type:Array
    },
    top_text2:{
      type:Array
    },
    dbclickStatus:{
      type:String
    },
   
  },
  created() {
    this.creatQrCode();
  },
  
  methods: {
    start() {
      var _this = this;
      clearTimeout(this.loop); //再次清空定时器，防止重复注册定时器
      // this.loop = setTimeout(() => {
      _this.getShareInfo();
      // }, 1000);
    },
    getTakeScreem(MainUrl, top_text1 , top_text2) {
      this.creatQrCode();
      let _this = this;
      this.imgsrc = MainUrl;
        console.log(_this.dbclickStatus)
   
      let loadNum = 0;
      this.$refs.mainImage.onload = function () {
          setTimeout(() => {
            html2canvas(_this.$refs.shareForm).then((canvas) => {
            let base64 = canvas.toDataURL("image/png");
            _this.compositeSrc = base64;
          });
          }, 500);
         
      }; 
       
    },
    end() {
      clearTimeout(this.loop); //清空定时器，防止重复注册定时器
    },
    closeShare() {
      this.imgsrc = "";
      this.$emit("closeShare");
    },
    creatQrCode() {
      var _this = this;
      QRCode.toDataURL(location.href).then((imgData) => {
            _this.qrcode = imgData;
          });
     /*  _this.$ajax
        .get( _this.$global.serverSrc+"/app/color/generator?fullUrl=" + Base64.encode(location.href) )
        .then((data) => {
          console.log(data)
          QRCode.toDataURL(data.data.result).then((imgData) => {
            _this.qrcode = imgData;
          });
        }) */


    
    },
    getShareInfo() {
      var _this = this;
      _this.$ajax
        .get("https://wechat.viewspread.com/home/getjsapi?url=" + location.href)
        .then((data) => {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: [
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "onMenuShareQQ",
            ],
          });
          // 分享
          wx.ready(function () {
            //分享到朋友圈
            wx.onMenuShareTimeline({
              title: "鹏孚隆 I PFLUON", // 分享标题
              desc: "我们无意于创造一种色彩，每个作品专注提取自然原色", // 分享描述
              // link: "78788787", // 分享链接
              imgUrl: "https://wechat.viewspread.com/images/pfluon.png", // 分享图标
              success: function () {},
            });
            //分享给朋友
            wx.onMenuShareAppMessage({
              title: "鹏孚隆 I PFLUON", // 分享标题
              desc: "我们无意于创造一种色彩，每个作品专注提取自然原色", // 分享描述
              // link: "<?php echo $weixin_share_url; ?>", // 分享链接
              imgUrl: "https://wechat.viewspread.com/images/pfluon.png", // 分享图标
              type: "", // 分享类型,music、video或link，不填默认为link
              dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
              success: function () {
                // alert('123')
              },
              fail: function (res) {
                console.log(res);
              },
            });
            //分享到QQ
            wx.onMenuShareQQ({
              title: "鹏孚隆 I PFLUON", // 分享标题
              desc: "我们无意于创造一种色彩，每个作品专注提取自然原色", // 分享描述
              // link: "<?php echo $weixin_share_url; ?>", // 分享链接
              imgUrl: "https://wechat.viewspread.com/images/pfluon.png", // 分享图标
              success: function () {},
            });
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.Share {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10001;
  // animation: show 0.36s;
  // -webkit-animation: show 0.36s; /* Safari */
}

.share-form {
  width: 340px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
  position: absolute;
  border-radius: 10px;
  z-index: 10002;
  background-color: #fff;
  opacity: 1;
  .compositeImage {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    box-sizing: border-box;
    z-index: 10;
    // object-fit: cover;
    // object-position: center;
  }
}
.form_header {
  width: 100%;
  height: 40px;
  position: relative;
}
.form-title {
  width: 100%;
  font-size: 26px;
  font-weight: 900;
  padding: 15px 20px;
  box-sizing: border-box;
  position: absolute;
}
.form-title img {
  width: 100%;
}
.form-close {
  float: right;
  width: 50%;
  text-align: right;
  margin: 0px 10px;
  // height: 100%;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}
.form-close p {
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
  color: #717380;
  // line-height: 10px;
}
.form-close .cn {
  font-size: 14px;
}
.form-close .en {
  transform: scale(0.8);
}
.form_content {
  width: 100%;
  margin-top: 40px;
  position: relative;
}

.form-item {
  padding: 10px 10px 20px;
}
.screenBox {
  padding: 10px;
  height: 370px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .mainImage {
    width: 100%;
    height: auto;
    border-radius: 10px;
    // object-fit: cover;
    // object-position: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .textImage {
    position: absolute;
    max-width: 100%;
    // height: auto;
    max-height: 20%;
    left: 20px;
  }
}

.form-item .item-left {
  float: left;
  width: 110px;
  padding: 5px;
}
.item-left img {
  width: 100px;
  height: 100px;
}
.item-right {
  height: 100px;
  padding-top: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
}
.item-right img {
  height: 100%;
  width: auto;
}
.item-right .right-title {
  color: #333;
}
.right-share {
  color: #717380;
}
.right-content {
  color: #333;
  font-size: 14px;
  font-weight: 600;
}
.colorContent {
    transform-origin: 0 0 0;
    background-color: transparent;
    // opacity: 0.5;
    .title {
      margin: 5px 0 10px;
      color: #626061;
      font-weight: 700;
      h4 {
        color: #626061;
        font-size: 1.44vh;
        letter-spacing: 0.21vh;
        span {
          color: #9c9b9b;
          font-size: 1.16vh;
          letter-spacing: 0.17vh;
          font-weight: normal;
        }
      }

      .color_text {
        color: #9c9b9b;
        font-size: 1.2vh;
        letter-spacing: 0.17vh;
        font-weight: normal;
      }
    }
  }

</style>