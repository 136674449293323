<template>
  <div class="hot1">
    

    <swiper :options="swiperOption2" class="mySwiper2" ref="mySwiper2" v-if="list.length>0">
            <swiper-slide class="ov" style="width:100%;height:100vh;overflow:auto; "  v-for="(item , index) in list" :key="index">
              <div class="hotImg">
                <img id="hotImg" :src="item.icon" alt="" data-clickmehtod="directTo"/>
                <!-- 关闭按钮 -->
                <div class="closeHot"  @click="changeShowHot">×</div>
                <!-- 直达按钮 -->
                <!--div class="direct"   data-clickmehtod="directTo" :style="'height:'+item.totalRect.rectH * biliW +'px;width:'+item.totalRect.rectW * biliW+ 'px;top:'+item.totalRect.beforey * biliW+ 'px;left:'+item.totalRect.beforex * biliW+'px'"></div-->
              </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      windowWidth:   document.documentElement.clientWidth,  //实时屏幕宽度
     
      list:[],
      listCount : 0,
     
      biliW:1,
      biliH:1
      
    };
  },
 

 
  created(){
    this.biliW = (this.windowWidth/375).toFixed(2)
   
   

    var that = this;
    this.swiperOption2={
     
        pagination: {
          el:'.swiper-pagination',
          clickable:true
        },
       
        slidesPerView: 1,
        on: {
          click: function (e) {//此处不能为箭头函数，保证函数内this指向swiper
            // this.realIndex 获取真正的屏页索引
            var realIdx = this.realIndex
           // var item = that.list[realIdx]
            console.log(e);
            // 获取属性绑定的函数名
            var fn = that[e.target.dataset.clickmehtod]
            if (fn && typeof fn === 'function') {
              fn( realIdx)
            }
          }
        }
         
      }
  },
  mounted() {
    
    this.getList();
    
  },
  methods: {
    changeShowHot() {
      this.$emit("changeShowHot", false);
    },
    directTo(index) {
      
      var content = this.list[index]
   
     /*  if(content.colorGroup==1){ */
        var list1 = JSON.parse(localStorage.getItem('insideData'));
          var list2 = JSON.parse(localStorage.getItem('outsideData'));
        var a= list1.find(item=>item.dataId==content.guanlianInside)
         var b= list2.find(item=>item.dataId==content.guanlianOutside)
         console.log(a, b)
         this.$emit("changeColor",  a.id , b.id);
      /* }else{
         var list = JSON.parse(localStorage.getItem('outsideData'));
        var b= list.find(item=>item.dataId==content.guanlian)
         this.$emit("changeColor", -1 , b.id );
      } */
      this.$emit("changeShowHot", false);
     /*  this.$emit("changeColor", -1, 85);
     this.$emit("changeShowHot", false);
       */
    },
    getList(){
       var _this = this
       let list = [
        {guanlianInside: '9459', guanlianOutside:'' , icon:'/static/image/1.jpg'},
        {guanlianInside: '14567', guanlianOutside:'' , icon:'/static/image/2.jpg'},
        {guanlianInside: '14571', guanlianOutside:'' , icon:'/static/image/3.jpg'},
        {guanlianInside: '14570', guanlianOutside:'' , icon:'/static/image/4.jpg'},
        {guanlianInside: '14566', guanlianOutside:'' , icon:'/static/image/5.jpg'},
        {guanlianInside: '14568', guanlianOutside:'' , icon:'/static/image/6.jpg'},
        {guanlianInside: '14569', guanlianOutside:'' , icon:'/static/image/7.jpg'},
       ]

      this.list = list


       /*
       this.$ajax({
              method: "get",
              url:  _this.$global.serverSrc+"/app/color/get/banner/list",
              }).then((res)=>{
              if(res.data.code==0){
                    var  list =res.data.result ;
                    list.forEach((item , index)=> {
                      list[index].totalRect = JSON.parse(item.bannerJson).totalRect
                        list[index].guanlianInside = JSON.parse(item.bannerJson).guanlianInside
                          list[index].guanlianOutside = JSON.parse(item.bannerJson).guanlianOutside
                    });
                    _this.list = list

              }
            })*/
    }
  },
};
</script>

<style lang="less" scoped>
.hot1 {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 99;
  background-color: #4ea1c3;
   &::-webkit-scrollbar {
      display: none;
    }
  .hotImg {
   /*  position: fixed;
    left: 50%; */
    // top: 50%;
  /*   transform: translateX(-50%); */
    width: 100%;
    height: auto;
    overflow: auto;
    max-width: 450px;
    
    // display: flex;
    // align-items: flex-start;
    // justify-content: center;
    &::-webkit-scrollbar {
      display: none;
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }
  .closeHot{
    position: absolute;
    width: 30px;
    height: 30px;
    right: 30px;
    top: 30px;
    text-align: center;
    line-height: 28px;
    background-color: #0000002b;
    cursor: pointer;
    border-radius: 50%;
    color: aliceblue;
  }
  
  .direct{
     position: absolute;
      cursor: pointer;
      
  } 
}
</style>
<style>
.swiper-slider::-webkit-scrollbar {
  display: none;
}
</style>
 