import { render, staticRenderFns } from "./Pageview.vue?vue&type=template&id=37ea4f74&scoped=true&"
import script from "./Pageview.vue?vue&type=script&lang=js&"
export * from "./Pageview.vue?vue&type=script&lang=js&"
import style0 from "./Pageview.vue?vue&type=style&index=0&id=37ea4f74&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ea4f74",
  null
  
)

export default component.exports