<template>
  <div class="concatMask">
    <div class="concatBox">
      <div class="concatus">
        <img src="../assets/concat/contactus.png" alt="" />
      </div>
      <div class="concatClose" @click="concatMaskClose">
        <img src="../assets/concat/close.png" alt="" />
      </div>
      <h4>Mr. Sun Yijun</h4>
      <p class="email">
        <a href="mailto:sunyj@pfluon.com.cn">sunyj@pfluon.com.cn</a>
      </p>
      <p>
        MP:<a href="tel:+8613600446255">+8613600446255</a
        >&nbsp;&nbsp;&nbsp;Tel＆Faz:<a href="tel:+865708272200"
          >+86-570-8272200</a
        >
      </p>
      <p></p>
      <p class="company">ZHEJIANG PFLUON NEW MATERIALS CO., LTD.</p>
      <p>
        No. 66 North Huayin Road, High-tech Industrial Park, Quzhou, Zhejiang,
        China. 324000.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    concatMaskClose() {
      this.$emit("concatMaskClose");
    },
  },
};
</script>

<style  lang="less" scoped>
.concatMask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  color: #fff;
  .concatBox {
    width: 95%;
    max-width: 350px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../assets/concat/concatBgc.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    // background-color: #fff;
    border-radius: 20px;
    padding: 35px 0 30px 20px;
    box-sizing: border-box;
    .concatus {
      width: 50%;
      position: absolute;
      bottom: 100%;
      transform: translateY(60%);
      right: 10%;
      img {
        width: 100%;
        height: auto;
      }
    }
    h4 {
      font-size: 18px;
    }
    .email {
      margin-bottom: 35px;
    }
    .company {
      width: 15%;
      white-space: nowrap;
      margin: 20px 0 5px 0;
      padding-bottom: 3px;
      border-bottom: 1px solid #fff;
      font-weight: 600;
    }
    a {
      color: #fff;
    }
    p {
      font-size: 12px;
    }
    .concatClose {
      position: absolute;
      top: 35px;
      right: 30px;
      width: 4%;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
        filter: invert(1) brightness(1.5);
      }
    }
  }
}
// 媒体查询
@media (min-width: 768px) {
  .concatMask {
    .concatBox {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
}
@media (min-width: 1440px) {
  .concatMask {
    .concatBox {
      transform: translate(-50%, -50%) scale(1.5);
    }
  }
}
</style>