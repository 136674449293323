<template>
<div class="formbg">
<div class="memberForm">
     <div   v-show="memberFormtype=='login'">
         <div class="login_from">
             <div class="form_header">
                   <div class="form-title">登录</div>
                   <div class="form-close"><img src="../assets/options/close.png" alt="" @click="doClose"> </div>
             </div>
             <div class="form_content">
                <div class="form-item" style="position: relative;">
                    <i class="icons icon-user"></i>
                    <input class="form-input" placeholder="请输入账号" type="text" name="" id="">
                </div>
                <div class="form-item" style="position: relative;">
                    <i class="icons icon-pwd" ></i>
                    <input class="form-input" placeholder="请输入密码" type="password" name="" id="">
                </div>

                <div class="form-item">
                   <button class="userLogin">登录</button>
                </div>
                 <div class="form-item">
                     <div class="toReg" @click="toReg">
                            没有账号？立即注册
                     </div>
                </div>
             </div>
         </div>
     </div>

     <div   v-show="memberFormtype=='reg'">
         <div class="login_from">
             <div class="form_header">
                   <div class="form-title">注册</div>
                   <div class="form-close">
                        <img src="../assets/options/close.png" alt="" @click="doClose">
                   </div>
             </div>
             <div class="form_content">
                <div class="form-item" style="position: relative;">
                    <i class="icons icon-user"></i>
                    <input class="form-input" placeholder="请输入账号" type="text" name="" id="">
                </div>
                <div class="form-item" style="position: relative;">
                    <i class="icons icon-pwd" ></i>
                    <input class="form-input" placeholder="请输入密码" type="password" name="" id="">
                </div>
                 <div class="form-item" style="position: relative;">
                    <i class="icons icon-pwd" ></i>
                    <input class="form-input" placeholder="请再次输入密码" type="password" name="" id="">
                </div>
                 <div class="form-item" style="position: relative;">
                    <i class="icons icon-phone" ></i>
                    <input class="form-input" placeholder="请输入手机号" type="password" name="" id="">
                </div>
                 <div class="form-item" style="position: relative;">
                    <i class="icons icon-msg" ></i>
                    <input class="form-input" placeholder="请输入验证码" type="password" name="" id="">
                    <span class="sendCode">请输入验证码</span>
                </div>
                <div class="form-item" style="text-align:center">
                    <input type="checkbox"/> <span @click="showPageView"  class="register_protocol"> 注册协议</span> 
                </div>
                <div class="form-item">
                   <button class="userLogin">注册</button>
                </div>
                
             </div>
         </div>
     </div>
    
  </div>
</div>
  
</template>

<script>
//import func from 'vue-editor-bridge';
export default {
  name: "Member",
  data() {
    return {
        
    };
  },
 props: {
    memberFormtype: {
      type: String
    }
  },
  methods:{
      toReg(){
          this.$emit('doMemberForm' , 'reg');
      },
      doClose(){
        this.$emit('doMemberForm' , '');
      },
      showPageView(){
          this.$emit('showPageView');
      }
  }
 
};
</script>

<style scoped>
.formbg{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 10001;
    animation: show 0.36s;
    -webkit-animation : show 0.36s; /* Safari */
}
.memberForm{
    width: 340px;
    left: 50%;
     top: 50%;
    transition: all .5s ease;
    transform: translate(-50%, -50%);    /* 50%为自身尺寸的一半 */
    position: absolute;
    border-radius: 10px;
    border:2px solid ccc;
    
    z-index: 10001;
    background-color: #fff;

}

.login_form{
      position: relative;
}
.form_header{
    width: 100%;
    height: 40px;
    position: relative;
}
.form-title{
    float:left;
    width:40%;
    font-size: 26px;
    font-weight: 900;
    margin:10px 20px;
}
.form-close{
     float:right;
    width:20%;
    text-align: right;
    margin: 10px;
    height: 100%;
    width: 24px;
    height: 24px;
    padding: 5px;
    cursor: pointer;
}
.form-close img{
    width: 20px;
    height:20px;
    margin:2px;
}
.form_content{
    width: 100%;
    margin-top: 40px;
    position: relative;
}
.form-item{
    margin: 25px 20px;

}
 
.form-item .form-input{
    line-height:40px;
    width: 90%;
    border:none;
    border-bottom: 1px solid #ccc;
    padding-left: 30px;
    outline: none;
    
}
input::-webkit-input-placeholder {
        color: #ccc;
      }
      input::-moz-input-placeholder {
        color: #ccc;
      }
      input::-ms-input-placeholder {
        color: #ccc;
      }
.sendCode{
    position: absolute;
    right: 0;
    z-index:5;
    top:10px;
    background-repeat: no-repeat;  
    background-size: 100% 100%;
    background-position: 0px 0px;  
    width: 100px; 
    font-size: 14px;
    height: 20px; 
     color: #4e6096; 
     font-weight: 600;
}
.icons{
    position: absolute;
    left: 0;
    z-index:5;
    top:10px;
    background-repeat: no-repeat;  
    background-size: 100% 100%;
    background-position: 0px 0px;  
    width: 16px; 
    height: 16px; 
}
.icon-user{
    background-image: url(../assets/options/user.png);  
}
.icon-pwd{
    background-image: url(../assets/options/pwd.png);  
}
.icon-phone{
    background-image: url(../assets/options/phone.png);  
}
.icon-msg{
    background-image: url(../assets/options/msg.png);  
}
 

.userLogin {
background-color:#345D9F;
	border-radius:28px;
    cursor: pointer;
    border:none;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:18px;
    padding:10px ;
    font-weight: 500;
    width: 100%;
	text-decoration:none;
    outline: none;
}
 .form-item span{
 cursor: pointer;
 }
.toReg{
    color: #4e6096;
    width: 100%;
    text-align: center;
    text-decoration:underline;
    margin-bottom: 50px;
   cursor: pointer;
}
.register_protocol{
    color: #345D9F;
}

 
</style>