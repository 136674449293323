import { render, staticRenderFns } from "./V3DApp.vue?vue&type=template&id=2a9aa8a2&scoped=true&"
import script from "./V3DApp.vue?vue&type=script&lang=js&"
export * from "./V3DApp.vue?vue&type=script&lang=js&"
import style0 from "./V3DApp.vue?vue&type=style&index=0&id=2a9aa8a2&prod&lang=less&"
import style1 from "./V3DApp.vue?vue&type=style&index=1&id=2a9aa8a2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a9aa8a2",
  null
  
)

export default component.exports