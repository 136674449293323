<template>
  <div class="home">
    <V3DApp v-if="loadStatus" />
  </div>
</template>
<script>
// @ is an alias to /src
import V3DApp from "../components/V3DApp.vue";
/*import insideData from '../assets/colorData/insideData.json'
import outsideData from '../assets/colorData/outsideData.json'
import keywordsData from '../assets/colorData/keywordsData.json'
import sacolorData from '../assets/colorData/sacolorData.json'*/
import insideData from '../../public/static/colorData/insideData.json'
import outsideData from '../../public/static/colorData/outsideData0329.json'
import keywordsData from '../../public/static/colorData/keywordsData.json'
import sacolorData from '../../public/static/colorData/sacolorData.json'
 
export default {
  name: "App",
   data() {
      return {
        loadStatus:false,
        
      }
    },
  components: {
    V3DApp,
  },
  methods:{
     downsort (arr = [], key)  {
          let tmp = JSON.parse(JSON.stringify(arr)); //深拷贝一份
          tmp.sort(function(a, b) {
              //console.log('a',a)
              a = a[key];
              b = b[key];
              return b - a;
          });

          return tmp;
      },
      chineseCharacters(property,sort){
          return function(a,b){
              var value1 = a[property].charAt(0);
              var value2 = b[property].charAt(0);
              if(sort == 'asc'){
                  return value1.localeCompare(value2);
              }else{
                  return value2.localeCompare(value1);
              }
              
          }
      }
 
  },
  mounted: function () {},
  created() {

        
              var _this = this;

            //var list1 = outsideData.sort(this.chineseCharacters('keyword','asc'));
           // var list2 =insideData.sort(this.chineseCharacters('keyword','asc'));
             var list1 = outsideData
             var list2 = insideData
                list1.forEach((item, index)=>{
                  list1[index].id = index
                 /* if(!item.dataId){
                  
                  list1[index].colorCode = item.textCN[2]
                  list1[index].dataId = 0
                  list1[index].recommend = []
                  list1[index].aliasName = ''
                  }*/
                 
               
                })  
              
               
                list2.forEach((item, index)=>{
                  list2[index].id = index
                
                })   
             

             
                 localStorage.setItem('insideData' ,JSON.stringify(list2) )
                 localStorage.setItem('outsideData' , JSON.stringify(list1) )
               
                 this.loadStatus = true    
                 localStorage.setItem('keywordList' ,JSON.stringify(keywordsData) )
                 localStorage.setItem('saColorList' ,  sacolorData) 
   /*this.$ajax({
          method: "post",
          url:  _this.$global.serverSrc+"/app/color/get/color/list/all?idMax=10613&colorStatus=2&sort=sortNum-down",
          }).then((res)=>{
          if(res.data.code==0){
              var insideList = []
              var outsideList = []

              res.data.result.forEach(function(item ){
                  var insideItem = {}
                  if(item.colorJson){
                    var jsonData = JSON.parse(item.colorJson)
                    
                    insideItem.Material= jsonData.Material
                    insideItem.MaterialId= item.materialId
                    insideItem.Light= jsonData.Light
                    insideItem.Value= jsonData.Value
                    insideItem.Size= jsonData.Size
                    insideItem.Switch= jsonData.Switch
                    insideItem.Color= jsonData.Color
                    insideItem.keyword= item.keyWord
                    insideItem.recommend= item.valueIds!=""?item.valueIds.split(',') :[200, 197, 64]
                    insideItem.dataId= item.id
                    insideItem.colorCode = item.colorCode
                    insideItem.aliasName = item.aliasName
                    insideItem.SwitchAO = item.SwitchAO?1:0
                    
                    if(item.colorGroup==1){
                      insideItem.Material =   insideItem.Material+'_Inside'
                       insideItem.textCN= [item.typeName ,"内涂系列" , item.colorCode , item.znName ]
                        insideItem.textEN= [item.typeName ,"Interior", item.colorCode , item.enName ]
                         insideItem.id= insideList.length
                       insideList.push(insideItem)
                    // insideList[item.id] = insideItem
                    }else{
                      insideItem.Material =   insideItem.Material+'_Outside'
                      insideItem.textCN= [item.typeName ,"外涂系列" , item.colorCode , item.znName ]
                    insideItem.textEN= [item.typeName ,"Exterior" , item.colorCode , item.enName ]
                    insideItem.id= outsideList.length
                      outsideList.push(insideItem)
                      // outsideList[item.id] = insideItem
                    }
                  
                  }
              })
                localStorage.setItem('insideData' ,JSON.stringify(insideList) )
              // localStorage.setItem('insideData' ,JSON.stringify(insideData) )
                 localStorage.setItem('outsideData' , JSON.stringify(outsideList)) 

              this.loadStatus = true

              //获取全部关键词列表  
        /*        this.$ajax({
                  method: "post",
                  url:  _this.$global.serverSrc+"/app/color/get/key/word/list",
                  }).then((res)=>{
                  if(res.data.code==0){
                        localStorage.setItem('keywordList' , JSON.stringify(res.data.result))  
                  }
                })

                  //获取全部撒点颜色列表  
                this.$ajax({
                  method: "post",
                  url:  _this.$global.serverSrc+"/app/color/get/sa/color/list",
                  }).then((res)=>{
                  if(res.data.code==0){
                      
                        var  list =res.data.result ;
                        var saColorList =[]
                        list.forEach((item ) => {
                          saColorList.push(item.colorValue)
                        });
                        localStorage.setItem('saColorList' ,saColorList)   
                  }
                })


*/
              
       /*  
             
           } 
        }) 
 
 */
 
   



    //初始化数据
    let insideId = this.getUrlKey("inside") ? this.getUrlKey("inside") : 112;
    let outsideId = this.getUrlKey("outside") ? this.getUrlKey("outside") : 218;
    let point = this.getUrlKey("SimPoint") ? this.getUrlKey("SimPoint") : -1;
    let light = this.getUrlKey("SimLight") ? this.getUrlKey("SimLight") : -1;
    let product = this.getUrlKey("product")
      ? this.getUrlKey("product")
      : "Product_0";
    let scene = this.getUrlKey("scene") ? this.getUrlKey("scene") : "Scene_0";
    let guide = this.getUrlKey("guide") ? this.getUrlKey("guide") : 0;
    let dbclick = this.getUrlKey("dbclick") ? this.getUrlKey("dbclick") : 0;
    this.$store.commit("setInsideId", insideId);
    this.$store.commit("setOutsideId", outsideId);
    this.$store.commit("setPoint", point);
    this.$store.commit("setLight", light);
    this.$store.commit("setProduct", product);
    this.$store.commit("setScene", scene);

    //初始化 URL
    this.$router.push({
      path: "/",
      query: {
        inside: insideId,
        outside: outsideId,
        SimPoint: point,
        SimLight: light,
        product: product,
        scene: scene,
        guide,
        dbclick,
      },
    });
  },
};
</script>
<style >
.tabbaroption hr {
  margin: 0px;
  width: calc(100% - 42px);
  height: 1px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-sizing: border-box;
  border: none;
}
.topbox {
  position: relative;
  top: 0;
  height: auto;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}

.search {
  position: relative;
  top: 0px;
  margin: 6px 0px;
  opacity: 1;
  z-index: 2;
}
.inputed {
  /* background-color: #fff; */
  border-radius: 20px;
  border: 2.5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAFKUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANWc2JIAAABtdFJOUwAc+uCw/uj9Ww/D1QZZXfJ7pZBK5q6nrL/AGdSo+bj2TGrQM42XyTgoNfzbB8pFxCYK7+f7loAnsvDFgi2FqTLf2iHjcuLGIARwrfQDQqK2qjq3Nt0SPMyTz8ERASuSCTCayJkuPU2RpNOzzQwQTMMIAAABTElEQVQ4y82U1XPCQBCHjwAJLi1QKO5eoO7u7u5uv///tQnM0LvLdegj+3Tf5ptsbmc3hHR5pGdCvujwUHB0+k9l4AztCB0IlW+v9tDoH8mO+7TTeUXvGD4AKXvTgqNdM3Bp5R2rHfgq/HKxDAQdnPQOfDboxFUOyLDOK1BtsKmTY5jTdOL6AtE6/wWnwB7NeeBNfxcTsEZhBqgL+gZsUDiJBUHn1rfQR+EEAqL+riJMEdAjkkxY+o+0QpHMFG9HFSmKbJgXOI44IhROAc96KQEkKZwDHvRSDKV+wtQr3fNOLzDLJF4AOzc+BhlSgU09ATXGMqgD5uHeffcIlPNULVmdYJduNGuAeX9nu3n3RKy5DYqFtyq36lzDbfObcnH1IHlcisAixcP2RimRMUIsQossLm+GjcZUINnqj2Y5O260ark7771z0Nutv6Qfm0RJlZP5AFEAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: auto 50%;
  height: 20px;
  width: 200px;
  outline: none;
  text-indent: 6px;
  background-color: #f5f6f8;
  font-size: 12px;
  color: grey;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>